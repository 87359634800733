import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Ceremony, MemberType } from '../../api/ceremony';
import { didPayForOrdination } from '../../helpers/checklistHelpers/ordainedHelper';
import useOrdained from '../../hooks/useOrdained';
import useOrders from '../../hooks/useOrders';
import { CeremonyDetailsCard } from '../../pages/Ceremony/CeremonySidebar/CeremonyDetailsCard/CeremonyDetailsCard';
import { useCeremonyBuilder } from '../../provider/ceremonyBuilderProvider';
import { useDashboard } from '../../provider/dashboardProvider';
import { Collaborators } from '../Collaborators/Collaborators';
import { SideMenu } from '../SideMenu/SideMenu';
import { SideMenuItem } from '../SideMenu/SideMenuItem';
import { Separator } from '../v2/Separator/Separator';

import { ViewWeddingMenuContent } from './ViewWeddingMenuContent';
import babySelectedSvg from './images/baby-selected.svg';
import babySvg from './images/baby.svg';
import checklistSelectedSvg from './images/checklist-selected.svg';
import checklistSvg from './images/checklist.svg';
import crSelectedSvg from './images/cr-selected.svg';
import crSvg from './images/cr.svg';
import designerSelectedSvg from './images/designer-selected.svg';
import designerSvg from './images/designer.svg';
import speechwritingMenuIcon from './images/speechwritingMenuIcon.svg';
import speechwritingMenuIconThin from './images/speechwritingMenuIconThin.svg';
import toastbuilderSelectedSvg from './images/toastbuilder-selected.svg';
import toastbuilderSvg from './images/toastbuilder.svg';
import tynSelectedSvg from './images/tyn-selected.svg';
import tynSvg from './images/tyn.svg';
import vowbuilderSelectedSvg from './images/vowbuilder-selected.svg';
import vowbuilderSvg from './images/vowbuilder.svg';

export const MenuContent = (props: { expanded: boolean }) => {
  const { expanded } = props;
  const {
    ceremony,
    couple1,
    couple2,
    currentUser,
    weddingPlanner,
    setOrdainedApplicationModalOpen,
  } = useDashboard();

  const { setSelectedTab } = useCeremonyBuilder();

  const navigate = useNavigate();

  const handleOnOrdinationClick = (ceremony: Ceremony) => {
    if (!ordersFetched || !ordainedFetched) {
      return;
    }
    if (!ordained) {
      setOrdainedApplicationModalOpen(true);
      navigate(`/ceremony/${ceremony.id}/checklist`);
    } else if (ordained && !didPayForOrdination(ordainedOrders)) {
      navigate(`/ceremony/${ceremony.id}/ordained`);
    } else {
      navigate(`/ceremony/${ceremony.id}/ordained`);
    }
  };

  const { ordainedOrders, ordersFetched, fetchOrders } = useOrders();
  const { ordained, fetchOrdained, ordainedFetched } = useOrdained();

  useEffect(() => {
    void fetchOrders();
    void fetchOrdained();
  }, []);

  const showTools = () => {
    let result = false;
    if (
      ceremony &&
      (location.pathname.indexOf(`/ceremony/${ceremony.id}/checklist`) >= 0 ||
        location.pathname.indexOf(`/ceremony/${ceremony.id}/designer`) >= 0 ||
        location.pathname.indexOf(`/ceremony/${ceremony.id}/vowbuilder`) >= 0 ||
        location.pathname.indexOf(`/ceremony/${ceremony.id}/toastbuilder`) >=
          0 ||
        location.pathname.indexOf(`/ceremony/${ceremony.id}/babylander`) >= 0 ||
        location.pathname.indexOf(`/ceremony/${ceremony.id}/reflections`) >=
          0 ||
        location.pathname.indexOf(`/ceremony/${ceremony.id}/thankyounotes`) >=
          0 ||
        location.pathname.indexOf(`/ceremony/${ceremony.id}/guest`) >= 0 ||
        location.pathname.indexOf(`/ceremony/${ceremony.id}/speechwriting`) >=
          0)
    ) {
      result = true;
    }
    return result;
  };

  const showCollaborators = () => {
    const result = false;

    if (ceremony && location.pathname.indexOf(`/ceremony/planner`) >= 0) {
      return false;
    }

    if (
      ceremony &&
      currentUser?.member_type !== MemberType.guests &&
      (location.pathname.indexOf(`/ceremony/${ceremony.id}/checklist`) >= 0 ||
        location.pathname.indexOf(`/ceremony/${ceremony.id}/designer`) >= 0 ||
        location.pathname.indexOf(`/ceremony/${ceremony.id}/vowbuilder`) >= 0 ||
        location.pathname.indexOf(`/ceremony/${ceremony.id}/toastbuilder`) >=
          0 ||
        location.pathname.indexOf(`/ceremony/${ceremony.id}/planner`) >= 0 ||
        location.pathname.indexOf(`/ceremony/${ceremony.id}/babylander`) >= 0 ||
        location.pathname.indexOf(`/ceremony/${ceremony.id}/speechwriting`) >=
          0 ||
        location.pathname.indexOf(`/ceremony/${ceremony.id}/reflections`) >=
          0 ||
        location.pathname.indexOf(`/ceremony/${ceremony.id}/thankyounotes`) >=
          0)
    ) {
      return true;
    }

    return result;
  };

  const showWPMenu = location.pathname.indexOf(`/ceremony/planner`) >= 0;

  const justifyMenu: string = showWPMenu ? 'justify-start' : 'justify-between';

  return (
    <div className={'PageWrapper-menu-content flex flex-col ' + justifyMenu}>
      {!ceremony && showWPMenu && (
        <ViewWeddingMenuContent expanded={expanded}></ViewWeddingMenuContent>
      )}
      {ceremony && currentUser && (
        <>
          {showWPMenu ? (
            <ViewWeddingMenuContent
              expanded={expanded}
            ></ViewWeddingMenuContent>
          ) : (
            <div>
              <CeremonyDetailsCard expanded={expanded} />

              {showTools() && (
                <div className='mt-6'>
                  <SideMenu expanded={expanded} title='WEDDING TOOLS'>
                    <SideMenuItem
                      renderIcon={() => <img src={checklistSvg} />}
                      renderSelectedIcon={() => (
                        <img src={checklistSelectedSvg} />
                      )}
                      text='Ceremony Checklist'
                      link={`/ceremony/${ceremony.id}/checklist`}
                      selected={
                        location.pathname.indexOf(
                          `/ceremony/${ceremony.id}/checklist`
                        ) >= 0
                      }
                      expanded={expanded}
                      display={currentUser?.member_type !== MemberType.guests}
                    />
                    <SideMenuItem
                      renderIcon={() => <img src={checklistSvg} />}
                      renderSelectedIcon={() => (
                        <img src={checklistSelectedSvg} />
                      )}
                      text='Onboarding Checklist'
                      link={`/ceremony/${ceremony.id}/checklist-guest`}
                      selected={
                        location.pathname.indexOf(
                          `/ceremony/${ceremony.id}/checklist-guest`
                        ) >= 0
                      }
                      expanded={expanded}
                      display={currentUser?.member_type === MemberType.guests}
                    />
                    <SideMenuItem
                      renderIcon={() => <img src={designerSvg} />}
                      renderSelectedIcon={() => (
                        <img src={designerSelectedSvg} />
                      )}
                      text='Ceremony Builder'
                      onClick={() => {
                        setSelectedTab('Chapters');
                        navigate(`/ceremony-designer/${ceremony.id}`);
                      }}
                      selected={
                        location.pathname.indexOf(
                          `/ceremony-designer/${ceremony.id}`
                        ) >= 0
                      }
                      expanded={expanded}
                      display={currentUser?.member_type !== MemberType.guests}
                    />
                    <SideMenuItem
                      renderIcon={() => <img src={vowbuilderSvg} />}
                      renderSelectedIcon={() => (
                        <img src={vowbuilderSelectedSvg} />
                      )}
                      text='Vow Builder'
                      link={`/ceremony/${ceremony.id}/vowbuilder`}
                      selected={
                        location.pathname.indexOf(
                          `/ceremony/${ceremony.id}/vowbuilder`
                        ) >= 0
                      }
                      expanded={expanded}
                      display={currentUser?.member_type !== MemberType.guests}
                    />
                    <SideMenuItem
                      renderIcon={() => <img src={toastbuilderSvg} />}
                      renderSelectedIcon={() => (
                        <img src={toastbuilderSelectedSvg} />
                      )}
                      text='Toast Builder'
                      link={`/ceremony/${ceremony.id}/toastbuilder`}
                      selected={
                        location.pathname.indexOf(
                          `/ceremony/${ceremony.id}/toastbuilder`
                        ) >= 0
                      }
                      expanded={expanded}
                      display={currentUser?.member_type !== MemberType.guests}
                    />
                    <SideMenuItem
                      renderIcon={() => <img src={toastbuilderSvg} />}
                      renderSelectedIcon={() => (
                        <img src={toastbuilderSelectedSvg} />
                      )}
                      text='Toast Builder'
                      link={`/ceremony/${ceremony.id}/guest`}
                      selected={
                        location.pathname.indexOf(
                          `/ceremony/${ceremony.id}/guest`
                        ) >= 0
                      }
                      display={currentUser?.member_type === MemberType.guests}
                      expanded={expanded}
                    />
                    <SideMenuItem
                      renderIcon={() => <img src={speechwritingMenuIconThin} />}
                      renderSelectedIcon={() => (
                        <img src={speechwritingMenuIcon} />
                      )}
                      text='Speechwriting Support'
                      link={`/ceremony/${ceremony.id}/speechwriting`}
                      selected={
                        location.pathname.indexOf(
                          `/ceremony/${ceremony.id}/speechwriting`
                        ) >= 0
                      }
                      expanded={expanded}
                    />
                    {process.env.REACT_APP_DISPLAY_REFLECTIONS === 'true' && (
                      <SideMenuItem
                        renderIcon={() => <img src={crSvg} />}
                        renderSelectedIcon={() => <img src={crSelectedSvg} />}
                        text='Community Reflections'
                        link={`/ceremony/${ceremony.id}/reflections`}
                        selected={
                          location.pathname.indexOf(
                            `/ceremony/${ceremony.id}/reflections`
                          ) >= 0
                        }
                        expanded={expanded}
                        display={
                          currentUser?.member_type === MemberType.bride ||
                          currentUser?.member_type === MemberType.groom ||
                          currentUser?.member_type === MemberType.other
                        }
                      />
                    )}
                    {process.env.REACT_APP_DISPLAY_THANKYOUNOTES === 'true' && (
                      <SideMenuItem
                        renderIcon={() => <img src={tynSvg} />}
                        renderSelectedIcon={() => <img src={tynSelectedSvg} />}
                        text='Thank You Notes'
                        link={`/ceremony/${ceremony.id}/thankyounotes`}
                        selected={
                          location.pathname.indexOf(
                            `/ceremony/${ceremony.id}/thankyounotes`
                          ) >= 0
                        }
                        expanded={expanded}
                        display={
                          currentUser?.member_type === MemberType.bride ||
                          currentUser?.member_type === MemberType.groom ||
                          currentUser?.member_type === MemberType.other
                        }
                      />
                    )}
                    <SideMenuItem
                      renderIcon={() => <img src={tynSvg} />}
                      renderSelectedIcon={() => <img src={tynSelectedSvg} />}
                      text='Ordination'
                      onClick={() => handleOnOrdinationClick(ceremony)}
                      selected={false}
                      expanded={expanded}
                      display={
                        currentUser?.member_type === MemberType.officiant
                      }
                    />
                  </SideMenu>
                </div>
              )}
              {showTools() && (
                <div className='mb-6'>
                  <Separator
                    className='border-forest-100 mb-6 mt-5 w-full'
                    variant='horizontal'
                  />
                  <SideMenu expanded={expanded} title='OTHER MILESTONES'>
                    <SideMenuItem
                      renderIcon={() => <img src={babySvg} />}
                      renderSelectedIcon={() => <img src={babySelectedSvg} />}
                      text='Baby Letter Builder'
                      link={`/ceremony/${ceremony.id}/babylander`}
                      selected={
                        location.pathname.indexOf(
                          `/ceremony/${ceremony.id}/babylander`
                        ) >= 0
                      }
                      expanded={expanded}
                    />
                  </SideMenu>
                </div>
              )}
              {showCollaborators() && (
                <>
                  <Separator
                    className='border-forest-100 mb-6 mt-5 w-full'
                    variant='horizontal'
                  />
                  <Collaborators
                    officiants={ceremony.members?.filter(
                      (m) =>
                        m.member_type === MemberType.officiant &&
                        m.id !== currentUser.id
                    )}
                    couple1={couple1}
                    couple2={couple2}
                    weddingPlanner={weddingPlanner}
                    currentUser={currentUser}
                    expanded={expanded}
                  />
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};
