import { Modal } from '../../../../../components/v2/Modal/Modal';
import { Typography } from '../../../../../components/v2/Typography/Typography';
import { capitalizeFirstLetter, capitalizeFirstLetterOfEachWord } from '../../../../../helpers/helper';
import { useDashboard } from '../../../../../provider/dashboardProvider';
import { ModalRoleItem } from '../../../../EventCreation/ModalRoleItem';

export const InvitePartnersModal = () => {
  const {
    couple1,
    couple2,
    invitePartnersModalOpen,
    setInvitePartnersModalOpen,
    setMemberType,
    setMemberForEditing,
    setAddModalOpen,
  } = useDashboard();

  return (
    <Modal
      fullWidth
      isOpen={invitePartnersModalOpen}
      onClose={() => setInvitePartnersModalOpen(false)}
    >
      <div>
        <Typography variant='primary' type='display-100' align='center'>
          Invite the couple to get started on crafting the ceremony together
          today
        </Typography>
        {!couple1?.email && (
          <ModalRoleItem
            text={`Invite ${capitalizeFirstLetterOfEachWord(
              couple1?.legal_name || ''
            )} as ${capitalizeFirstLetter(couple1?.member_type || '')}`}
            onClick={() => {
              setInvitePartnersModalOpen(false);
              setMemberType(couple1?.member_type);
              setMemberForEditing(couple1);
              setAddModalOpen(true);
            }}
            selected={false}
            disabled={!!couple1?.email}
          />
        )}

        {!couple2?.email && (
          <ModalRoleItem
            text={`Invite ${capitalizeFirstLetterOfEachWord(
              couple2?.legal_name || ''
            )} as ${capitalizeFirstLetter(couple2?.member_type || '')}`}
            onClick={() => {
              setInvitePartnersModalOpen(false);
              setMemberType(couple2?.member_type);
              setMemberForEditing(couple2);
              setAddModalOpen(true);
            }}
            selected={false}
            disabled={!!couple2?.email}
          />
        )}
      </div>
    </Modal>
  );
};
