import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  NewPaymentBody,
  NewSubscriptionBody,
  OrderType,
  PayOrderBody,
  PayOrderResponse,
  PayOrdersResponse,
  PaymentMethod,
  createCheckoutSession,
  newMultipleOrdersPaymentSession,
  placeNewOrder,
  placeNewOrders,
} from '../../api/paywall';
import closeIcon from '../../assets/images/icons/close-x-v2.svg';
import Button from '../../components/v2/Button/Button';
import CampaignItem from '../../components/v2/CampaignItem/CampaignItem';
import { Typography } from '../../components/v2/Typography/Typography';
import { sendCustomerIoEventHandler } from '../../helpers/customerIoHelper';
import { getAPIErrorMessage } from '../../helpers/helper';
import useLoader from '../../hooks/useLoader';
import useUser from '../../hooks/useUser';
import { usePlanner } from '../../provider/plannerProvider';
import { useSnackbar } from '../../provider/snackbarProvider';
import { AddCeremonyModal } from '../Ceremony/PlannerDashboard/AddCeremonyModal';
import { InfoBox } from '../ModulesV2/RingExchange/InfoBox';

import PaymentModalPayPerCeremonyContent from './PaymentModalPayPerCeremonyContent';
import PaymentModalSubscriptionContent from './PaymentModalSubscriptionContent';

const PaymentContent = () => {
  const {
    setShowPaymentModal,
    numberOfCeremoniesBeingAdded,
    setNumberOfCeremoniesToPayFor,
    numberOfCeremoniesToPayFor,
    fetchWeddingPlannerStatus,
    weddingPlannerStatus,
  } = usePlanner();

  const navigate = useNavigate();

  const { user, fetchCeremonies } = useUser();

  const [selectedTab, setSelectedTab] = useState<
    'subscription' | 'perCeremony'
  >('subscription');

  const params = useParams();
  //const navigate = useNavigate();
  const { openSnackBar } = useSnackbar();
  const { showLoader, hideLoader } = useLoader();

  const [availableSlots, setAvailableSlots] = useState<number>(0);

  useEffect(() => {
    const defaultAvailableSlots = user?.hasActiveWeddingPlannerSubscription
      ? 50
      : 1;

    if (user?.activeCeremoniesCount) {
      const availableSlotsTemp =
        defaultAvailableSlots - user?.activeCeremoniesCount;
      if (availableSlotsTemp < 0) {
        setAvailableSlots(0);
      } else {
        setAvailableSlots(availableSlotsTemp);
      }
    } else {
      setAvailableSlots(defaultAvailableSlots);
    }

    void sendCustomerIoEventHandler('WP Payment Process Started', {});
  }, [user]);

  const createSession = async (ref: string, subscriptionPlanId: number) => {
    try {
      const payBody: NewSubscriptionBody = {
        ref: ref,
        subscriptionPlanId: subscriptionPlanId,
      };
      const sessionResponse: PayOrderResponse = await createCheckoutSession(
        payBody
      );
      if (sessionResponse.success) {
        window.location.href = sessionResponse.message;
        return;
      } else {
        openSnackBar(sessionResponse.message, 'error');
      }
    } catch (err) {
      openSnackBar(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getAPIErrorMessage(err as any),
        'error'
      );
    } finally {
      hideLoader();
    }
  };

  const createPaymentSession = async (ref: string) => {
    try {
      const payBody: NewPaymentBody = {
        ref: ref,
        currency: 'USD',
      };
      const sessionResponse: PayOrderResponse =
        await newMultipleOrdersPaymentSession(payBody);
      if (sessionResponse.success) {
        window.location.href = sessionResponse.message;
        return;
      } else {
        openSnackBar(sessionResponse.message);
      }
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      openSnackBar(getAPIErrorMessage(err as any));
    } finally {
      hideLoader();
    }
  };

  const placeOrder = async (subscription_plan_id: number, price: number) => {
    showLoader();
    void sendCustomerIoEventHandler('WP Plan Selected', {});
    try {
      const reqBody: PayOrderBody = {
        ceremony_id: params.ceremonyId || '',
        subscription_plan_id: subscription_plan_id,
        payment_method: PaymentMethod.card,
        value: price,
        type: OrderType.ceremony_designer,
        payment_type: 'subscription',
        currency: 'USD',
      };

      const orderResponse: PayOrderResponse = await placeNewOrder(reqBody);
      if (orderResponse.success) {
        await createSession(orderResponse.data.ref, subscription_plan_id);
      } else {
        openSnackBar(orderResponse.message, 'error');
      }
    } catch (err) {
      openSnackBar(getAPIErrorMessage(err), 'error');
    } finally {
      hideLoader();
    }
  };

  const placeOrders = async (orders: PayOrderBody[]) => {
    showLoader();
    try {
      const orderResponse: PayOrdersResponse = await placeNewOrders({
        orders: orders,
      });
      if (orderResponse.success) {
        await createPaymentSession(orderResponse.data.refs);
      } else {
        openSnackBar(orderResponse.message, 'error');
      }
    } catch (err) {
      openSnackBar(getAPIErrorMessage(err), 'error');
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <>
        <CampaignItem
          regularComponent={<></>}
          renderCampaignComponent={(discountPercentage, couponCode) => (
            <div className='w-full px-1 md:px-2 py-1 md:py-2 text-center bg-copper-primary text-white mb-2 cursor-pointer underline-offset-4'>
              {`GET ${discountPercentage}% OFF | USE CODE: `}
              <u>{couponCode}</u>
              {` | OFFER ENDS SOON!`}
            </div>
          )}
        />
      </>
      <div className='flex flex-col justify-center items-center'>
        <div className='flex justify-end w-full max-w-[550px] text-right cursor-pointer'>
          <Typography className='mb-8 max-w-[550px] text-right' type='body-400'>
            <div
              className='flex flex-row items-center gap-2 cursor-pointer'
              onClick={() => {
                navigate('/ceremony/planner/dashboard');
              }}
            >
              Back to Dashboard
              <div className='cursor-pointer ml-auto right-[16px] top-[16px]'>
                <img alt='close' src={closeIcon} />
              </div>
            </div>
          </Typography>
        </div>

        <Typography className='mb-2' type='display-600'>
          Upgrade Access
        </Typography>
        <Typography className='mb-8 max-w-[550px] text-center' type='body-400'>
          Provide seamless access for clients to take advantage of Provenance’s
          tools while monitoring and supporting their progress.
        </Typography>
        {numberOfCeremoniesBeingAdded > availableSlots && (
          <InfoBox
            className='mb-4'
            text={`You are trying to activate ${numberOfCeremoniesBeingAdded} new weddings and you only have ${availableSlots} available slots. Please upgrade your plan to proceed.`}
          />
        )}

        {/* tabs selector */}
        <div className='py-6 flex justify-center w-full'>
          <div>
            <div
              onClick={() => setSelectedTab('subscription')}
              className={`flex justify-center min-w-[160px] rounded-xl px-4 py-2 cursor-pointer ${
                selectedTab === 'subscription' ? 'bg-cosmicLatte-dark' : ''
              }`}
            >
              <Typography type='display-50' className='min-w-max'>
                Subscription
              </Typography>
            </div>
          </div>
          <div className='flex flex-col justify-center items-center'>
            <div
              onClick={() => setSelectedTab('perCeremony')}
              className={`flex justify-center min-w-[160px] rounded-xl px-4 py-2 cursor-pointer ${
                selectedTab === 'perCeremony' ? 'bg-cosmicLatte-dark' : ''
              }`}
            >
              <Typography type='display-50' className='min-w-max'>
                Pay per Client
              </Typography>
            </div>
          </div>
        </div>

        {selectedTab === 'subscription' ? (
          <PaymentModalSubscriptionContent placeOrder={placeOrder} />
        ) : (
          <PaymentModalPayPerCeremonyContent />
        )}

        {numberOfCeremoniesToPayFor > 0 && (
          <>
            <Typography type='body-400' className='mt-5'>
              or...
            </Typography>
            <Typography type='display-400' className='mt-5'>
              Invite your clients now, pay later
            </Typography>
            <Typography
              type='body-400'
              className='mt-2 max-w-[550px] text-center'
            >
              Invite your client(s) to Provenance without upgrading their access
              - you or your client(s) can upgrade at a later date (with 20% off
              our typical fee!)
            </Typography>
            <Button
              className='mt-6'
              onClick={() => {
                setShowPaymentModal(false);
                setNumberOfCeremoniesToPayFor(0);
                openSnackBar('Clients invited!', 'success');
              }}
            >
              Invite client(s)
            </Button>
            <Button variant='text' className='mt-2'>
              Learn more
            </Button>
          </>
        )}
      </div>
      {weddingPlannerStatus && (
        <AddCeremonyModal
          weddingPlannerStatus={weddingPlannerStatus}
          refetch={() => {
            void fetchCeremonies();
            void fetchWeddingPlannerStatus();
          }}
        />
      )}
    </>
  );
};

export default PaymentContent;
