import { useEffect, useState } from 'react';

import { useActiveCampaign } from '../../../provider/campaignProvider';
import { useAccessibleModule } from '../../../provider/moduleAccessProvider';

type CampaignItemProps = {
  renderCampaignComponent: (
    discountPercentage: number,
    couponCode: string,
    campaignText?: string
  ) => JSX.Element;
  regularComponent?: JSX.Element;
  hideIfPaid?: boolean;
};

const CampaignItem = (props: CampaignItemProps) => {
  const { renderCampaignComponent, regularComponent, hideIfPaid } = props;
  const { hasActiveCampaign, campaignText, couponCode, discountPercentage } =
    useActiveCampaign();

  const {
    hasAccessToVowsAi,
    accessibleModuleIdentifiersFetched,
    fetchingAccessibleModulesInProgress,
  } = useAccessibleModule();

  const [isPaid, setIsPaid] = useState<boolean>(false);

  useEffect(() => {
    if (
      accessibleModuleIdentifiersFetched &&
      !fetchingAccessibleModulesInProgress &&
      hideIfPaid
    ) {
      setIsPaid(hasAccessToVowsAi && hideIfPaid);
    } else {
      setIsPaid(false);
    }
  }, [
    accessibleModuleIdentifiersFetched,
    hasAccessToVowsAi,
    fetchingAccessibleModulesInProgress,
    hideIfPaid,
  ]);

  if (!accessibleModuleIdentifiersFetched && hideIfPaid) {
    return <></>;
  }

  if (!hasActiveCampaign || isPaid) {
    if (regularComponent) {
      return regularComponent;
    } else {
      return <></>;
    }
  } else {
    return !isPaid ? (
      renderCampaignComponent(discountPercentage, couponCode, campaignText)
    ) : (
      <></>
    );
  }
};

export default CampaignItem;
