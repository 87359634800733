/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useEffect, useState } from 'react';

import {
  CeremonyAccessibleModulesResponse,
  PricingOption,
  getCeremonyAccessibleModules,
  CeremonyPlanResponse,
} from '../api/ceremony';
import { Module } from '../hooks/useActiveModule';
import { QuestionModule } from '../pages/Modules/common/moduleQuestion/ModuleQuestion';

type AccessibleModuleContextType = {
  accessibleModuleIdentifiers?: Array<string>;
  accessibleModuleIdentifiersFetched: boolean;
  fetchAccessibleModulesIdentifiers: (
    ceremonyId: string | number
  ) => Promise<void>;
  paymentCompleted: boolean;
  fetchingAccessibleModulesInProgress: boolean;
  hasAccessToDirectionalQuestions: boolean;
  hasAccessToRitualsAndReadings: boolean;
  hasAccessToOfficiantRemarks: boolean;
  hasAccessToDeclarationOfIntent: boolean;
  hasAccessToVowsCeremony: boolean;
  hasAccessToPronouncementCelebration: boolean;
  hasAccessToVowsAi: boolean;
  hasAccessToInviteOfficiant: boolean;
  hasAccessToInviteGuests: boolean;
  hasAccessToInviteWeddingPlanner: boolean;
  hasAccessToWelcomeToast: boolean;
  hasAccessToToastBuilder: boolean;
  checkIfHasAccessToModule: (module: Module) => boolean;
};

export const AccessibleModuleContext =
  React.createContext<AccessibleModuleContextType>({
    fetchingAccessibleModulesInProgress: false,
    paymentCompleted: false,
    accessibleModuleIdentifiersFetched: false,
    accessibleModuleIdentifiers: [],
    hasAccessToDirectionalQuestions: true,
    hasAccessToRitualsAndReadings: true,
    hasAccessToOfficiantRemarks: false,
    hasAccessToDeclarationOfIntent: false,
    hasAccessToVowsCeremony: false,
    hasAccessToPronouncementCelebration: false,
    hasAccessToVowsAi: false,
    hasAccessToInviteOfficiant: false,
    hasAccessToInviteGuests: false,
    hasAccessToInviteWeddingPlanner: false,
    hasAccessToWelcomeToast: false,
    hasAccessToToastBuilder: false,
    fetchAccessibleModulesIdentifiers: async (
      ceremonyId: string | number
    ) => {},
    checkIfHasAccessToModule: (module: Module) => false,
  });

export const ModuleAccessProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [accessibleModuleIdentifiers, setIdentifiers] = useState<Array<string>>(
    [QuestionModule.directional, QuestionModule.rituals]
  );
  const [
    accessibleModuleIdentifiersFetched,
    setAccessibleModuleIdentifiersFetched,
  ] = useState<boolean>(false);
  const [paymentCompleted, setPaymentCompleted] = useState<boolean>(false);
  const [hasAccessToDirectionalQuestions, setHasAccessToDirectionalQuestions] =
    useState<boolean>(false);
  const [hasAccessToRitualsAndReadings, setHasAccessToRitualsAndReadings] =
    useState<boolean>(false);
  const [hasAccessToOfficiantRemarks, setHasAccessToOfficiantRemarks] =
    useState<boolean>(false);
  const [hasAccessToDeclarationOfIntent, setHasAccessToDeclarationOfIntent] =
    useState<boolean>(false);
  const [hasAccessToVowsCeremony, setHasAccessToVowsCeremony] =
    useState<boolean>(false);
  const [
    hasAccessToPronouncementCelebration,
    setHasAccessToPronouncementCelebration,
  ] = useState<boolean>(false);
  const [hasAccessToVowsAi, setHasAccessToVowsAi] = useState<boolean>(false);
  const [hasAccessToInviteOfficiant, setHasAccessToInviteOfficiant] =
    useState<boolean>(false);
  const [hasAccessToInviteGuests, setHasAccessToInviteGuests] =
    useState<boolean>(false);
  const [hasAccessToInviteWeddingPlanner, setHasAccessToInviteWeddingPlanner] =
    useState<boolean>(false);
  const [hasAccessToWelcomeToast, setHasAccessToWelcomeToast] =
    useState<boolean>(false);
  const [hasAccessToToastBuilder, setHasAccessToToastBuilder] =
    useState<boolean>(false);
  const [
    fetchingAccessibleModulesInProgress,
    setFetchingAccessibleModulesInProgress,
  ] = useState<boolean>(true);

  useEffect(() => {
    setHasAccessToToastBuilder(
      checkIfHasAccessToModule(Module.GuestToastAi) ||
        checkIfHasAccessToModule(Module.InviteGuests)
    );
    setHasAccessToDirectionalQuestions(
      checkIfHasAccessToModule(Module.DirectionalQuestions)
    );
    setHasAccessToRitualsAndReadings(
      checkIfHasAccessToModule(Module.RitualsAndReadings)
    );
    setHasAccessToOfficiantRemarks(
      checkIfHasAccessToModule(Module.OfficiantRemarks)
    );
    setHasAccessToDeclarationOfIntent(
      checkIfHasAccessToModule(Module.DeclarationOfIntent)
    );
    setHasAccessToVowsCeremony(checkIfHasAccessToModule(Module.VowsCeremony));
    setHasAccessToPronouncementCelebration(
      checkIfHasAccessToModule(Module.PronouncementCelebration)
    );
    setHasAccessToVowsAi(checkIfHasAccessToModule(Module.VowsAi));
    setHasAccessToInviteOfficiant(
      checkIfHasAccessToModule(Module.InviteOfficiant)
    );
    setHasAccessToInviteGuests(checkIfHasAccessToModule(Module.InviteGuests));
    setHasAccessToInviteWeddingPlanner(
      checkIfHasAccessToModule(Module.InviteWeddingPlanner)
    );
    setHasAccessToWelcomeToast(checkIfHasAccessToModule(Module.WelcomeToast));
  }, [accessibleModuleIdentifiers]);

  const fetchAccessibleModulesIdentifiers = async (
    ceremonyId: string | number
  ) => {
    setIdentifiers([QuestionModule.directional, QuestionModule.rituals]);
    setFetchingAccessibleModulesInProgress(true);
    try {
      const accessibleModulesResponse: CeremonyPlanResponse =
        await getCeremonyAccessibleModules(ceremonyId.toString());

      if (accessibleModulesResponse.success) {
        setAccessibleModuleIdentifiersFetched(true);
        if (
          accessibleModulesResponse.data.accessibleModules &&
          accessibleModulesResponse.data.accessibleModules.modules.length > 0
        ) {
          setPaymentCompleted(true);
          accessibleModulesResponse.data.accessibleModules.modules.forEach(
            (module) => {
              setIdentifiers((previousAccessibleModuleIdentifiers) => [
                ...(previousAccessibleModuleIdentifiers || []),
                module.module_identifier,
              ]);
            }
          );
        }
      }
    } catch (error) {
      setFetchingAccessibleModulesInProgress(false);
    }finally {
      setFetchingAccessibleModulesInProgress(false);
    }
  };

  const checkIfHasAccessToModule = (module: Module) => {
    if (
      accessibleModuleIdentifiers &&
      accessibleModuleIdentifiers.length > 0 &&
      accessibleModuleIdentifiers.filter((m) => m === module).length > 0
    ) {
      return true;
    }

    return false;
  };

  const value = {
    fetchingAccessibleModulesInProgress,
    paymentCompleted,
    accessibleModuleIdentifiers,
    accessibleModuleIdentifiersFetched,
    fetchAccessibleModulesIdentifiers,
    checkIfHasAccessToModule,
    hasAccessToDirectionalQuestions,
    hasAccessToRitualsAndReadings,
    hasAccessToOfficiantRemarks,
    hasAccessToDeclarationOfIntent,
    hasAccessToVowsCeremony,
    hasAccessToPronouncementCelebration,
    hasAccessToVowsAi,
    hasAccessToInviteOfficiant,
    hasAccessToInviteGuests,
    hasAccessToInviteWeddingPlanner,
    hasAccessToWelcomeToast,
    hasAccessToToastBuilder,
  };
  // @ts-ignore
  return (
    <AccessibleModuleContext.Provider value={value}>
      {children}
    </AccessibleModuleContext.Provider>
  );
};

export const useAccessibleModule = () => {
  return useContext(AccessibleModuleContext);
};
