import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
  SvgIcon,
} from '@mui/material';
import classNames from 'classnames';

import './Checkbox.scss';
import { CheckboxProps } from './typings.d';



const disableRipples = {
  disableFocusRipple: true,
  disableTouchRipple: true,
  disableRipple: true,
};

// import CheckboxIcon from '../../../assets/images/svg/checkbox-unchecked.svg';
// import CheckboxIconChecked from '../../../assets/images/svg/checkbox-checked.svg';
//implemented as code svgs because images created a wierd flickering bug in  Chorme
function UncheckedIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='0.5' y='0.5' width='23' height='23' stroke='#9AA3A0' />
    </svg>
  );
}

function CheckedIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_345_6599)'>
        <g clipPath='url(#clip1_345_6599)'>
          <path
            d='M10.4837 15.7638C10.1793 16.0787 9.67853 16.0787 9.37414 15.7638L6.23198 12.5648C5.92267 12.2549 5.92267 11.7451 6.23198 11.4352C6.53638 11.1203 7.03716 11.1203 7.34155 11.4352L9.92892 14.0694L15.6584 8.23618C15.9628 7.92127 16.4636 7.92127 16.768 8.23618C17.0773 8.54608 17.0773 9.05592 16.768 9.36582L10.4837 15.7638Z'
            fill='#354740'
          />
        </g>
      </g>
      <rect x='0.5' y='0.5' width='23' height='23' stroke='#9AA3A0' />
      <defs>
        <clipPath id='clip0_345_6599'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
        <clipPath id='clip1_345_6599'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export const Checkbox = ({ label, ...checkboxProps }: CheckboxProps) => {
  return (
    <div className={classNames('Checkbox')}>
      <FormControlLabel
        control={
          <MuiCheckbox
            icon={<UncheckedIcon />}
            checkedIcon={<CheckedIcon />}
            {...checkboxProps}
            {...disableRipples}
          />
        }
        {...{ label }}
      />
    </div>
  );
};
