/* eslint-disable */
import { useNavigate, useParams } from 'react-router-dom';

import { ModuleWrapper } from '../components';

import bookHeartIcon from '../../../assets/images/icons/book-heart.svg';
import useQuestions from '../../../hooks/useQuestions';
import useCeremony from '../../../hooks/useCeremony';
import { ModuleStatus } from '../enum';
import { LoveStoryQuestion } from './LoveStoryQuestion';
import { useEffect, useMemo, useState } from 'react';
import { CTAProvider } from '../../../provider/ctaProvider';
import Button from '../../../components/v2/Button/Button';
import { Module } from '../../../hooks/useActiveModule';
import renderText from '../../../helpers/renderText';
import { useCeremonyBuilder } from '../../../provider/ceremonyBuilderProvider';
import {
  generalLog,
  updateCeremonyPrivacySettings,
  UpdateCeremonyPrivacySettings,
} from '../../../api/ceremony';
import { GuiltModal } from '../components/GuiltModal/GuiltModal';
import { Question } from '../../Modules/common/moduleQuestion/ModuleQuestion';
import PrivacySettingsModal from './PrivacySettingsModal';
import useLoader from '../../../hooks/useLoader';
import Loader from '../../../components/Loader/loader';
import { useSnackbar } from '../../../provider/snackbarProvider';
import GenerateButton from '../components/ModuleWrapper/GenerateButton';

export type LoveStoryQuestionType = {
  mainQuestion: Question;
  couple2Question: Question;
};

export const LoveStoryModule = ({
  hideWrapper = false,
  onModuleStatusChange,
  setRemarksMinNumQuestionsAnswered,
}: {
  hideWrapper?: boolean;
  onModuleStatusChange?: (status: ModuleStatus) => void;
  setRemarksMinNumQuestionsAnswered?: (status: boolean) => void;
}) => {
  const navigate = useNavigate();
  const ceremonyId = useParams()?.ceremonyId;
  const requiredQuestions = 7;
  const [openGuiltModal, setOpenGuiltModal] = useState(false);
  const [loveStoryQuestions, setLoveStoryQuestions] = useState<
    LoveStoryQuestionType[]
  >([]);

  const [privacySettingsModalOpen, setPrivacySettingsModalOpen] =
    useState(false);

  const {
    setDraftingModalOpen,
    setSelectedModule,
    setGenerateDraftInitiated,
    setSelectedTab,
  } = useCeremonyBuilder();

  const {
    questions: allRemarksQuestions,
    fetchQuestions: fetchRemarksQuestions,
  } = useQuestions();
  const {
    questions: allCouple1Questions,
    fetchQuestions: fetchCouple1Questions,
  } = useQuestions();
  const {
    questions: allCouple2Questions,
    fetchQuestions: fetchCouple2Questions,
  } = useQuestions();

  useEffect(() => {
    if (
      (!loveStoryQuestions || loveStoryQuestions.length === 0) &&
      allCouple1Questions &&
      allCouple2Questions &&
      allRemarksQuestions
    ) {
      let questions = allRemarksQuestions.map((question, idx) => {
        const couple2Question = allCouple2Questions.find((c2q) => {
          return c2q.question === question.question;
        });
        if (!couple2Question) return null;
        return {
          mainQuestion: question,
          couple2Question: couple2Question,
        } as LoveStoryQuestionType;
      });
      const filtered = questions.filter(
        (q) => q !== null
      ) as LoveStoryQuestionType[];
      setLoveStoryQuestions(filtered || []);
    }
  }, [allRemarksQuestions, allCouple1Questions, allCouple2Questions]);

  const {
    ceremony,
    couple1,
    couple2,
    officiant,
    currentUser,
    currentRole,
    fetchCeremonyById,
  } = useCeremony();
  const members = { couple1, couple2, officiant, currentUser };

  const { isShowing, showLoader, hideLoader } = useLoader();
  const { openSnackBar } = useSnackbar();

  useEffect(() => {
    if (ceremonyId) {
      void fetchCeremonyById(ceremonyId);
    }
  }, [ceremonyId]);

  useEffect(() => {
    if (ceremony) {
      void fetchRemarksQuestions(
        Module.OfficiantRemarks,
        ceremony.id.toString()
      );
      void fetchCouple1Questions(Module.Couple1, ceremony.id.toString());
      void fetchCouple2Questions(Module.Couple2, ceremony.id.toString());
    }
  }, [ceremony]);

  const startGenerating = () => {
    setOpenGuiltModal(false);
    if (ceremonyId) {
      setSelectedModule(Module.OfficiantRemarks);
      setSelectedTab('Ceremony');
      setDraftingModalOpen(true);
      setGenerateDraftInitiated(true);
      navigate(`/ceremony-designer/${ceremonyId}`);
      generalLog({
        ceremony_id: ceremonyId,
        activity: `Generate - Love Story Module`,
      });
    } else {
      navigate(-1);
    }
  };

  const handleSaveChanges = async (
    hideOfficiantAddress: boolean,
    hidePartnerResponses: boolean
  ) => {
    showLoader();
    if (!ceremony) {
      hideLoader();
      return;
    }
    try {
      const data: UpdateCeremonyPrivacySettings = {
        id: ceremony?.id,
        hide_officiant_address: hideOfficiantAddress,
        hide_partner_responses: hidePartnerResponses,
      };

      const response = await updateCeremonyPrivacySettings(data);
      if (response.success) {
        setPrivacySettingsModalOpen(false);
        openSnackBar('Privacy settings updated successfully', 'success');
        await fetchCeremonyById(ceremony.id.toString());
      } else {
        openSnackBar(response.message, 'error');
      }
    } catch (err) {
      hideLoader();
      if (err instanceof Error) {
        openSnackBar(err.message, 'error');
      } else {
        openSnackBar('Cannot update Privacy settings', 'error');
      }
    }

    hideLoader();
  };

  const handleGenerateClick = ({
    moduleStatus,
  }: {
    moduleStatus: ModuleStatus;
  }) => {
    if (ceremonyId) {
      if (moduleStatus !== ModuleStatus.completed) setOpenGuiltModal(true);
      else startGenerating();
    } else {
      navigate(-1);
    }
  };

  return (
    <CTAProvider
      totalQuestions={loveStoryQuestions?.length || 0}
      requiredQuestions={requiredQuestions}
      onModuleStatusChange={onModuleStatusChange}
      module={Module.OfficiantRemarks}
    >
      <Loader isShowing={isShowing} hide={hideLoader} allowHide={false} />
      {currentRole && ceremony && (
        <PrivacySettingsModal
          isOpen={privacySettingsModalOpen}
          onClose={() => {
            setPrivacySettingsModalOpen(false);
          }}
          onDoneClick={handleSaveChanges}
          currentRole={currentRole}
          ceremony={ceremony}
        />
      )}
      <ModuleWrapper
        hideWrapper={hideWrapper}
        chapterProps={{
          chapter: 'Chapter 2',
          renderIcon: () => <img src={bookHeartIcon} draggable={false} />,
          iconWrapperProps: { className: 'bg-rose-400' },
        }}
        summaryProps={{
          moduleName: "Officiant's Address",
          moduleDescription: renderText('love_story_about'),
          numberOfQuestions: 10,
          estimatedTime: 45,
          extraButton:
            ceremony?.created_by === currentUser?.user_id ? (
              <div>
                <Button
                  variant='secondary'
                  onClick={() => {
                    setPrivacySettingsModalOpen(true);
                  }}
                >
                  Chapter Privacy
                </Button>
              </div>
            ) : null,
        }}
        questionsHeaderProps={{
          renderCenterButton: (
            moduleStatus: ModuleStatus,
            status: 'saving' | 'saved' | 'unsaved'
          ) => (
            <Button
              size='100'
              disabled={
                (moduleStatus !== ModuleStatus.completed &&
                  moduleStatus !== ModuleStatus.requiredAnswered) ||
                status !== 'saved'
              }
              onClick={() => handleGenerateClick({ moduleStatus })}
            >
              Generate Section
            </Button>
          ),
          renderRightButton: (moduleStatus: ModuleStatus) => (
            <Button
              size='100'
              variant='secondary'
              onClick={() => {
                if (ceremonyId) {
                  navigate(`/ceremony-designer/${ceremonyId}`);
                } else {
                  navigate(-1);
                }
              }}
            >
              Exit & Save
            </Button>
          ),
        }}
      >
        {ceremony &&
          loveStoryQuestions
            ?.slice(0, requiredQuestions)
            .map((questionData, idx) => {
              return (
                <LoveStoryQuestion
                  key={`question-${idx}`}
                  required={true}
                  questionNumber={idx + 1}
                  ceremonyId={ceremony?.id}
                  remarksQuestionInitial={questionData.mainQuestion}
                  couple2QuestionInitial={questionData.couple2Question}
                  members={members}
                  totalNumberOfQuestions={loveStoryQuestions.length}
                  mainQuestionPrivate={
                    ceremony.hide_partner_responses === true &&
                    currentUser?.id !== couple1?.id &&
                    currentUser?.id !== officiant?.id
                  }
                  couple2QuestionPrivate={
                    ceremony.hide_partner_responses === true &&
                    currentUser?.id !== couple2?.id &&
                    currentUser?.id !== officiant?.id
                  }
                  createdByOfficiant={ceremony?.created_by === officiant?.id}
                />
              );
            })}
        {ceremony &&
          loveStoryQuestions
            ?.slice(requiredQuestions)
            .map((questionData, idx) => {
              return (
                <LoveStoryQuestion
                  key={`question-${idx}`}
                  questionNumber={idx + 1 + requiredQuestions}
                  ceremonyId={ceremony?.id}
                  remarksQuestionInitial={questionData.mainQuestion}
                  couple2QuestionInitial={questionData.couple2Question}
                  members={members}
                  totalNumberOfQuestions={loveStoryQuestions.length}
                  mainQuestionPrivate={
                    ceremony.hide_partner_responses === true &&
                    currentUser?.id !== couple1?.id &&
                    currentUser?.id !== officiant?.id
                  }
                  couple2QuestionPrivate={
                    ceremony.hide_partner_responses === true &&
                    currentUser?.id !== couple2?.id &&
                    currentUser?.id !== officiant?.id
                  }
                  createdByOfficiant={ceremony?.created_by === officiant?.id}
                />
              );
            })}

        <div className='flex justify-center mb-16'>
          <GenerateButton
            fullWidth={true}
            onClickWithStatus={({ moduleStatus }) => {
              handleGenerateClick({ moduleStatus });
            }}
            size='200'
          />
        </div>

        <GuiltModal
          isOpen={openGuiltModal}
          totalQuestions={loveStoryQuestions.length}
          onCancelClick={() => {
            setOpenGuiltModal(false);
          }}
          onContinue={startGenerating}
        />
      </ModuleWrapper>
    </CTAProvider>
  );
};
