/* eslint-disable @typescript-eslint/no-empty-function */
import moment from 'moment';
import React, { useContext } from 'react';

import { Module } from '../hooks/useActiveModule';
import { BuilderContextType, useBuilder } from '../hooks/useBuilder';
import { useCeremonyChapterStatus } from '../hooks/useCeremonyChapterStatus';
import { ModuleStatus } from '../pages/ModulesV2/enum';

type CeremonyBuilderExtraProps = {
  selectedModule: Module | null;
  setSelectedModule: (module: Module | null) => void;
  ceremonyDetailsStatus: ModuleStatus;
  setCeremonyDetailsStatus: (moduleStatus: ModuleStatus) => void;
  readingsStatus: ModuleStatus;
  setReadingsStatus: (moduleStatus: ModuleStatus) => void;
  remarksStatus: ModuleStatus;
  setRemarksStatus: (moduleStatus: ModuleStatus) => void;
  declarationStatus: ModuleStatus;
  setDeclarationStatus: (moduleStatus: ModuleStatus) => void;
  vowsCeremonyStatus: ModuleStatus;
  setVowsCeremonyStatus: (moduleStatus: ModuleStatus) => void;
  pronouncementStatus: ModuleStatus;
  setPronouncementStatus: (moduleStatus: ModuleStatus) => void;
  isModuleCompleted: (selectedModule: Module | null) => boolean;
  resetAllStatuses: () => void;
  selectedTab: 'Ceremony' | 'Chapters';
  setSelectedTab: (tab: 'Ceremony' | 'Chapters') => void;
};

export const BuilderContext = React.createContext<
  BuilderContextType & CeremonyBuilderExtraProps
>({
  generateDraftInitiated: false,
  setGenerateDraftInitiated: () => {},
  getQAStatus: async () => {},
  textGenerated: false,
  setTextGenerated: () => {},
  textGenerateComplete: false,
  setTextGenerateComplete: () => {},
  versionHistoryModalOpen: false,
  setVersionHistoryModalOpen: () => {},
  aiGeneratedText: '',
  setAiGeneratedText: () => {},
  aiGeneratedTextRendered: '',
  setAiGeneratedTextRendered: () => {},
  aiGeneratedTextIndex: 0,
  setAiGeneratedTextIndex: () => {},
  textGenerating: false,
  setTextGenerating: () => {},
  selectedVersionId: 0,
  setSelectedVersionId: () => {},
  versions: [],
  setVersions: () => {},
  totalMinutes: 0,
  setTotalMinutes: () => {},
  draftingModalOpen: false,
  setDraftingModalOpen: () => {},
  completedModalOpen: false,
  setCompletedModalOpen: () => {},
  selectedModule: Module.DirectionalQuestions,
  setSelectedModule: () => {},
  generationError: false,
  setGenerationError: () => {},
  getMomentOfLatestVersion: () => moment(),
  ceremonyDetailsStatus: ModuleStatus.notStarted,
  setCeremonyDetailsStatus: () => {},
  readingsStatus: ModuleStatus.notStarted,
  setReadingsStatus: () => {},
  remarksStatus: ModuleStatus.notStarted,
  setRemarksStatus: () => {},
  declarationStatus: ModuleStatus.notStarted,
  setDeclarationStatus: () => {},
  vowsCeremonyStatus: ModuleStatus.notStarted,
  setVowsCeremonyStatus: () => {},
  pronouncementStatus: ModuleStatus.notStarted,
  setPronouncementStatus: () => {},
  isModuleCompleted: () => false,
  resetAllStatuses: () => {},
  selectedTab: 'Ceremony',
  setSelectedTab: () => {},
});

export const CeremonyBuilderProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    generateDraftInitiated,
    setGenerateDraftInitiated,
    getQAStatus,
    textGenerated,
    setTextGenerated,
    textGenerateComplete,
    setTextGenerateComplete,
    versionHistoryModalOpen,
    setVersionHistoryModalOpen,
    setSelectedVersionId,
    setTotalMinutes,
    aiGeneratedText,
    setAiGeneratedText,
    aiGeneratedTextRendered,
    setAiGeneratedTextRendered,
    aiGeneratedTextIndex,
    setAiGeneratedTextIndex,
    textGenerating,
    setTextGenerating,
    selectedVersionId,
    versions,
    setVersions,
    totalMinutes,
    draftingModalOpen,
    setDraftingModalOpen,
    completedModalOpen,
    setCompletedModalOpen,
    generationError,
    setGenerationError,
    getMomentOfLatestVersion,
  } = useBuilder();

  const {
    ceremonyDetailsStatus,
    setCeremonyDetailsStatus,
    readingsStatus,
    setReadingsStatus,
    remarksStatus,
    setRemarksStatus,
    declarationStatus,
    setDeclarationStatus,
    vowsCeremonyStatus,
    setVowsCeremonyStatus,
    pronouncementStatus,
    setPronouncementStatus,
  } = useCeremonyChapterStatus();

  const isModuleCompleted = (selectedModule: Module | null) => {
    switch (selectedModule) {
      case Module.DirectionalQuestions:
        return (
          ceremonyDetailsStatus === ModuleStatus.completed ||
          ceremonyDetailsStatus === ModuleStatus.requiredAnswered
        );
      case Module.OfficiantRemarks:
        return (
          remarksStatus === ModuleStatus.completed ||
          remarksStatus === ModuleStatus.requiredAnswered
        );
      case Module.RitualsAndReadings:
        return (
          readingsStatus === ModuleStatus.completed ||
          readingsStatus === ModuleStatus.requiredAnswered
        );
      case Module.VowsCeremony:
        return (
          vowsCeremonyStatus === ModuleStatus.completed ||
          vowsCeremonyStatus === ModuleStatus.requiredAnswered
        );
      case Module.DeclarationOfIntent:
        return (
          declarationStatus === ModuleStatus.completed ||
          declarationStatus === ModuleStatus.requiredAnswered
        );
      case Module.PronouncementCelebration:
        return (
          pronouncementStatus === ModuleStatus.completed ||
          pronouncementStatus === ModuleStatus.requiredAnswered
        );
      default:
        return false;
    }
  };
  const resetAllStatuses = () => {
    setSelectedModule(null);
    setCeremonyDetailsStatus(ModuleStatus.notStarted);
    setReadingsStatus(ModuleStatus.notStarted);
    setRemarksStatus(ModuleStatus.notStarted);
    setDeclarationStatus(ModuleStatus.notStarted);
    setVowsCeremonyStatus(ModuleStatus.notStarted);
    setPronouncementStatus(ModuleStatus.notStarted);
  };

  const [selectedModule, setSelectedModule] = React.useState<Module | null>(
    null
  );

  const [selectedTab, setSelectedTab] = React.useState<'Chapters' | 'Ceremony'>(
    'Ceremony'
  );

  const value = {
    generateDraftInitiated,
    setGenerateDraftInitiated,
    getQAStatus,
    textGenerated,
    setTextGenerated,
    textGenerateComplete,
    setTextGenerateComplete,
    versionHistoryModalOpen,
    setVersionHistoryModalOpen,
    setSelectedVersionId,
    setTotalMinutes,
    aiGeneratedText,
    setAiGeneratedText,
    aiGeneratedTextRendered,
    setAiGeneratedTextRendered,
    aiGeneratedTextIndex,
    setAiGeneratedTextIndex,
    textGenerating,
    setTextGenerating,
    selectedVersionId,
    versions,
    setVersions,
    totalMinutes,
    draftingModalOpen,
    setDraftingModalOpen,
    completedModalOpen,
    setCompletedModalOpen,
    selectedModule,
    setSelectedModule,
    generationError,
    setGenerationError,
    getMomentOfLatestVersion,
    ceremonyDetailsStatus,
    setCeremonyDetailsStatus,
    readingsStatus,
    setReadingsStatus,
    remarksStatus,
    setRemarksStatus,
    declarationStatus,
    setDeclarationStatus,
    vowsCeremonyStatus,
    setVowsCeremonyStatus,
    pronouncementStatus,
    setPronouncementStatus,
    isModuleCompleted,
    resetAllStatuses,
    selectedTab,
    setSelectedTab,
  };
  // @ts-ignore
  return (
    <BuilderContext.Provider value={value}>{children}</BuilderContext.Provider>
  );
};

export const useCeremonyBuilder = () => {
  return useContext(BuilderContext);
};
